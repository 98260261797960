import "./accordions.js";
import "./home.js";

document.querySelector("html").classList.remove("no-js");

document.addEventListener("DOMContentLoaded", function () {
  document.querySelector(".menu-search-toggle").addEventListener("click", function (event) {
    event.preventDefault();
    document.querySelector(".search-container").classList.toggle("search-opened");
  });
  document.querySelector(".mobile-search").addEventListener("click", function (event) {
    event.preventDefault();
    document.querySelector(".mobile-search-container").classList.toggle("search-opened");
  });
  document.querySelector("#close-form").addEventListener("click", function (e) {
    addEventListener(".search-container").classList.removeClass("search-opened");
    e.preventDefault;
  });
});

jQuery(function ($) {
  // Remove the 'no-js' <body> class

  var classList = new Array();
  var selectArr = new Array();
  var clickArr = new Array();

  /**
   * Tools
   */
  function formatCurrency(number) {
    var n = number.split("").reverse().join("");
    var n2 = n.replace(/\d\d\d(?!$)/g, "$&,");
    return "$" + n2.split("").reverse().join("");
  }

  var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  var qs = (function (a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
      var p = a[i].split("=", 2);
      if (p.length == 1) b[p[0]] = "";
      else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
  })(window.location.search.substr(1).split("&"));

  // $("#search-trigger").on("click", function (e) {
  // 	// Open up the search function
  // });

  // Refills tabs -- https://refills.bourbon.io/
  $(".accordion-tabs-minimal").each(function (index) {
    $(this)
      .children("li")
      .first()
      .children("a")
      .addClass("is-active")
      .next()
      .addClass("is-open")
      .show();
  });

  var defaultTabLink = $(".accordion-tabs-minimal .tab-link.is-active").attr(
    "id"
  );

  if (defaultTabLink) {
    var defaultClass = defaultTabLink.replace("-target", "");
    $('.tab-content .tab-inner-wrap[class*="' + defaultTabLink + '"]').addClass(
      "show"
    );
  }

  $(".accordion-tabs-minimal").on("click", "li > a.tab-link", function (event) {
    if (!$(this).hasClass("is-active")) {
      event.preventDefault();
      var accordionTabs = $(this).closest(".accordion-tabs-minimal");
      accordionTabs.find(".is-open").removeClass("is-open").hide();

      $(this).next().toggleClass("is-open").toggle();
      accordionTabs.find(".is-active").removeClass("is-active");
      $(this).addClass("is-active");
    } else {
      event.preventDefault();
    }
  });

  // Donate page gravity form modifications
  $(".input-tribute-type, .input-tribute-name").appendTo(
    ".input-gift-options div ul li:first-child"
  );
  $(".input-tribute-type>label, .input-tribute-name>label").hide();

  // Fixes for Gravity Forms
  // -- help with default hidden state
  // -- fix to Other choice enabling the proper style when needed
  $("#gform_fields_3, #gform_fields_22")
    .find(".gfield_html")
    .addClass("hide-until-radios-clicked");
  $(
    "#gform_fields_3 .ginput_container_radio .gfield_radio input[name*='input_1'], #gform_fields_22 .ginput_container_radio .gfield_radio input[name*='input_1']"
  ).on("change", function () {
    var theValue = $(this).val();

    $("#gift-value-amount")
      .attr("data-gift-value", theValue)
      .addClass("selectedHTML");

    $("#gform_fields_3, #gform_fields_22")
      .find(".gfield_html")
      .removeClass("hide-until-radios-clicked");

    $(
      "#gform_fields_3 .gfield_html, #gform_fields_22 .gfield_html"
    ).removeClass("showing-html");

    if ($(this).val() == "gf_other_choice") {
      $("#field_3_13, #field_22_13")
        .css("display", "block")
        .siblings(".gfield_html")
        .css("display", "none");
    } else {
      $("#field_3_13, #field_22_13").css("display", "none");
    }

    var nonhiddenEl = $(
      "#gform_fields_3 .gfield_html, #gform_fields_22 .gfield_html"
    ).filter(function () {
      return $(this).css("display") !== "none";
    });

    nonhiddenEl.addClass("showing-html");
  });

  // When button clicked, dynamically populate the field below
  $("#gift-value-amount").on("click", function (e) {
    var selectedGiftLevelEl = $(
      "#gform_fields_3 .showing-html .section-title, #gform_fields_22 .showing-html .section-title"
    ).text();
    var selectedGiftLevelArr = selectedGiftLevelEl.split(/\s-\s(.*)/g);
    var giftLevel = selectedGiftLevelArr[0];

    var selectedGiftAmount = $("#gift-value-amount").attr("data-gift-value");
    // format the gift amount to US currency
    if (selectedGiftAmount == "gf_other_choice") {
      // do nothing, stays blank
      var formattedGiftAmt = "";
    } else {
      var formattedGiftAmt = formatCurrency(selectedGiftAmount);
    }

    // show success message of gift amount correctly added
    $("#gift-value-button-wrapper").append(
      '<span class="success" style="display: inline-block;">Gift Amount Applied!</span>'
    );
    setTimeout(function () {
      $(".success").css("opacity", 0);
    }, 3000);
    setTimeout(function () {
      $(".success").remove();
    }, 5000);

    // Fill in the form field in the next form -- amount, and gift level
    $("#input_2_2, #input_21_2").attr("value", formattedGiftAmt);
    $(
      "#gform_2 .gform_fields li:first-of-type input.gform_hidden, #gform_21 .gform_fields li:first-of-type input.gform_hidden"
    ).attr("value", giftLevel);

    e.preventDefault;
  });

  // Input value gets updated from keyup/input if not dynamically added
  // Clear on focus
  $("#input_2_2, #input_21_2")
    .on("input", function () {
      var insertedAmt = this.value;
      $(this).attr("value", insertedAmt);
    })
    .on("focus", function () {
      $(this).attr("value", "");
    });

  // Tickets update purchase button
  $(".grid.tickets .single-event select.pretty-select").on(
    "change",
    function updateTicketButton() {
      const $ticketURL = $(this).val();
      const $purchaseBtn = $(this).closest(".col-content").find(".purchase-ticket");
      $purchaseBtn.attr("href", "" + $ticketURL + "");
      if ($purchaseBtn.attr("href") != "null") {
        $purchaseBtn.addClass("date-chosen");
      } else {
        $purchaseBtn.removeClass("date-chosen");
      }
    }
  );

  // Give select box containers focus when select has it
  $("select")
    .focus(function () {
      $(this).closest(".select-container").removeClass("unfocused");
      $(this).closest(".select-container").addClass("focused");
    })
    .blur(function () {
      $(this).closest(".select-container").removeClass("focused");
      $(this).closest(".select-container").addClass("unfocused");
    });

  // If coming from support page, and amount was chosen, auto-click that button
  // -- At the moment, refraining from selecting gift value button
  if (qs["amount"]) {
    $('input[value="' + qs["amount"] + '"]').click();
  }

  // Initialize slick only on certain templates
  if (!$("body").hasClass("non-slider")) {
    if ($("body").hasClass("page-template-donate")) {
      $("#input_3_1, #input_22_1")?.slick({
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 5,
              infinite: false,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 4,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              dots: false,
            },
          },
        ],
      });
    } else if ($("body").hasClass("home")) {
      $(".home-slider")?.slick({
        infinite: true,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });
    } else if ($("body").hasClass("support")) {
      $(".support-slider")?.slick({
        infinite: true,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });
    }
    // sponsors-slider
    var sponsorsSlick = $(".sponsors-slider");

    sponsorsSlick.slick &&
      sponsorsSlick?.slick({
        infinite: true,
        arrows: true,
        dots: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 5,
              infinite: true,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 4,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              dots: false,
            },
          },
        ],
      });
  }

  $(".dropdown-target").on("click", function (e) {
    $(this).parent().toggleClass("nav-area-open");
  });


  // Mobile announcement widget
  // -- set cookie when clicked
  // -- expire 14 days later

  if (document.cookie.match(/^(.*;)?\s*dismiss\s*=\s*[^;]+(.*)?$/)) {
    $(".announcement.mobile").addClass("dismissed");
  }

  $(".dismiss, .announcement.mobile a").click(function () {
    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + 3600000 * 24 * 14);

    $(".announcement.mobile").addClass("dismissed");
    document.cookie = "dismiss=true;expires=" + expire.toGMTString();
  });

  // FAQ custom styling
  $(".ufaq-faq-display-style-Default .ufaq-faq-toggle > a > div > span").html(
    ""
  );

  $(".menu-tickets-container ul li .main-stage").removeClass("disabled");
  $(".menu-tickets-container ul li:not(.main-stage)").addClass("disabled");

  $(".tickets-wrap .tab-header-and-content .tab-link").on(
    "click",
    function (e) {
      var clickedIdValue = $(this).attr("id"),
        ticketsGrid = $(".grid.tickets"),
        selectedTicketsGrid = $(".grid.tickets." + clickedIdValue),
        submenuItems = $(".menu-tickets-container ul li"),
        emptyMessage = $(".nothing-found");

      ticketsGrid.removeClass("show");
      selectedTicketsGrid.addClass("show");
      submenuItems.removeClass("disabled");

      !ticketsGrid.hasClass(clickedIdValue)
        ? emptyMessage.addClass("show")
        : emptyMessage.removeClass("show");

      submenuItems.each(function () {
        if (!$(this).hasClass(clickedIdValue)) {
          $(this).addClass("disabled");
        } else {
          $(this).removeClass("disabled");
        }
      });

      e.preventDefault;
    }
  );

  $(".single-performance .tab-content .tab-inner-wrap:first-of-type").addClass(
    "show"
  );
  $(".single-performance .tab-header-and-content .tab-link").on(
    "click",
    function (e) {
      var clickedIdValue = $(this).attr("id").replace("-target", ""),
        tabsInner = $(".single-performance .tab-inner-wrap"),
        selectedContent = $(
          ".tab-content .tab-inner-wrap." + clickedIdValue + "-content"
        );

      tabsInner.removeClass("show");
      selectedContent.addClass("show");

      e.preventDefault;
    }
  );
  // Set defaults on page load for above tabbed areas
  $(".grid.tickets.main-stage").addClass("show");
  $(".tab-content .ticket-purchase-content").addClass("show");

  // Get height from support grid image
  // -- add as min-height to info div
  $(".support.grid .two-col.image-container").each(
    function () {
      var that = $(this),
        thatHeight = that.height();

      if ($(window).width >= 768) {
        that.siblings(".two-col.info").css("min-height", thatHeight);
      }
    }
  );

  // Default state for season grid
  $(".season.grid .col").addClass("show");

  function check_classes(event) {
    var changeVal = $(this).find("option:selected").val(),
      theId = $(this).attr("id"),
      menuItem = $(this).parent(".menu-item");

    if (menuItem) {
      $(this)
        .parent()
        .parent()
        .find(".active-filter")
        .removeClass("active-filter");

      $(this).addClass("active-filter");
    }

    if (changeVal && classList.indexOf(changeVal) === -1) {
      selectArr.length = 0;
      if (changeVal === "default") {
        selectArr.length = 0;
      } else {
        selectArr.length = 0;
        selectArr.push(changeVal);
      }
    }
    if (theId && classList.indexOf(clickId) === -1) {
      var clickId = theId.replace("-filter", "");
      if (clickId === "all") {
        selectArr.length = 0;
        clickArr.length = 0;
        // $(".posts-filter select option")
        //   .removeAttr("selected")
        //   .filter("[value=default]")
        //   .attr("selected", true);
        $(".season.grid .col").addClass("show");
      } else {
        clickArr.length = 0;
        clickArr.push(clickId);
      }
    }

    // Build class list to search through
    classList = selectArr.concat(clickArr);

    $(".season.grid .col").removeClass("show hide");
    if (classList === undefined || classList.length == 0) {
      $(".season.grid .col").removeClass("hide show");
    } else {
      var classListJoin = "." + classList.join(".");
      var classesToShow = $(".season.grid .col" + classListJoin + "");
      var classesToHide = $(".season.grid .col:not(" + classListJoin + ")");

      classesToShow.addClass("show");
      classesToHide.addClass("hide");
    }

    if (
      !$(".season.grid .col").hasClass("show") &&
      $(".season.grid .col").hasClass("hide")
    ) {
      $(".season.grid span.empty-response").html(
        "No performances found with current filter choices."
      );
    } else {
      $(".season.grid span.empty-response").html("");
    }
  }

  $("body.past-performances .posts-filter button").click(
    [classList, selectArr, clickArr],
    check_classes
  );
  $("body.past-performances .posts-filter select").change(
    [classList, selectArr, clickArr],
    check_classes
  );

  // Wrap iframes in div to style. Fixes iPhone scrolling bug.
  $("iframe").wrap("<div class='iframe-fix'/>");

  $(".class-participants .menu-wrap").each(function () {
    var totalHeight = 0;
    $(this)
      .find("ul li, .area-title")
      .each(function () {
        totalHeight += $(this).outerHeight(true);
      });
    $(this)
      .find("ul li, .area-title, .inner-list")
      .each(function () {
        totalHeight += $(this).outerHeight(true);
      });

    var observersLength = $(this).find(".observers-list").length;

    if (observersLength > 0) {
      totalHeight += $(this).find(".observers-list").outerHeight(true);
    }

    var facultyLength = $(this).find(".faculty-list").length;

    if (facultyLength > 0) {
      totalHeight += $(this).find(".faculty-list").outerHeight(true);
    }

    $(this)
      .find(".menu-title")
      .click(function () {
        var menuWrap = $(this).parent();
        if (menuWrap.hasClass("opened")) {
          menuWrap.removeClass("opened");
          menuWrap.find(".menu-inner").css("max-height", 0);
          return false;
        } else {
          menuWrap.addClass("opened");
          menuWrap.find(".menu-inner").css("max-height", totalHeight);
          return false;
        }
      });
  });

  $(".class-participants .menu-wrap:first-of-type .menu-title").click();

  // sticky sidebar seating-map
  function stickySidebar() {
    // default
    $(".sidebar").addClass("stickied");

    var lastTable = $("table.pricing:last-of-type");
    var lastTableBottom = lastTable.offset().top + lastTable.innerHeight();

    console.log("lastTableBottom: " + lastTableBottom);

    $(".sb-scroll").scroll(function () {
      var scrollBottom = $(this).scrollTop() + $(window).innerHeight();

      console.log("scrollBottom: " + scrollBottom);
      console.log("lastTableBottom: " + lastTableBottom);

      // first check offset, then add height so it's actually the bottom
      if (lastTableBottom <= scrollBottom) {
        $(".sidebar").removeClass("stickied");
      } else {
        if (!$(".sidebar").hasClass("stickied")) {
          $(".sidebar").addClass("stickied");
        }
      }
    });
  }

  // Run on load and any resizing of the browser window
  if ($("body").hasClass("sticky-sidebar")) {
    stickySidebar();
    $(window).resize(stickySidebar);
  }

  if ($("body").hasClass("blog")) {
    $(".nav-wrap__date-wrap")?.slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      draggable: true,
      autoplay: false,
      prevArrow: $(".nav-wrap__year-slick-prev"),
      nextArrow: $(".nav-wrap__year-slick-next"),
    });
  }
});

